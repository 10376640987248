$main-color: #1ab394;
$background-color: #ffffff;

$text-color: #404040;
$text-color-inverse: #ffffff;
$text-gray: #a0a0a0;
$dark-gray: #9b9b9b;
$border-color: #d4d4d4;
$scroll-color: #e2e2e2;
$svg-color: rgba(#9f9f9f, 0.4);
$svg-hover-color: #9f9f9f;
$root-font-size: 14px;
$background-gray: #f7f7f7;
$detail-color: $background-gray;

@import "./table.scss";

.sa-datatables__table {
  width: 100%;
  background: $text-color-inverse;
}

.sa-datatables__details-container.sa-datatables__details-container.sa-datatables__details-container {
  padding: 0;
  border: none;
}

.sa-table__header-extensions {
  width: calc(100% - 590px);
}

.sa-table__svg-button svg {
  background-color: $background-color;
}

.sa-table__detail-row .sa-table__svg-button svg {
  background-color: $detail-color;
}

//redifine datatables' styles
.sa-datatables {
  .dt-buttons {
    margin-left: 20px;
    float: left;
    margin-bottom: 10px;
  }

  .dataTables_wrapper {
    background-color: $background-gray;
    padding-top: 30px;
    padding-bottom: 50px;
  }

  .dataTables_paginate {
    float: right;
    margin-right: 20px;
    padding-top: 10px;
    &:last-child {
      margin-top: 15px;
    }

    .ellipsis {
      color: $text-gray;
      margin-right: 15px;
    }
  }

  .paginate_button {
    color: $text-gray;
    margin-right: 15px;
    cursor: pointer;
    outline: none;

    &:last-child {
      margin-right: 0;
    }
  }

  .paginate_button.previous {
    @include arrow();
    transform: rotate(90deg);
    margin-right: 20px;
  }

  .paginate_button.next {
    @include arrow();
    transform: rotate(270deg);
    margin-left: 20px;
  }

  .paginate_button.current {
    font-weight: bold;
    border-bottom: 1px solid $text-gray;
  }

  .dataTables_info {
    color: $text-gray;
    float: left;
    margin-left: 20px;
    margin-top: 15px;
  }

  table {
    border-spacing: 0;
    border-bottom: 1px solid #ddd;
    overflow: auto;
  }

  table.dataTable {
    & > thead > tr > th {
      border: 1px solid $border-color;
      border-left: none;
      border-bottom: none;
      padding: 13px 20px;
      position: relative;
      vertical-align: top;
      pointer-events: none;

      &:first-child {
        border-right: none;
        border-left: 1px solid $border-color;
      }
    }

    & > thead > tr > th,
    & > tbody > tr > td {
      min-width: 208px;
    }

    & > thead > tr > th:first-child,
    & > tbody > tr > td:first-child {
      min-width: auto;
      border-left: 1px solid #ddd;
      border-right: none;
    }

    & > tbody > tr > td {
      padding: 20px;
      border-top: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }
  }

  .dataTables_scrollHead table {
    border-bottom: none;
  }

  .dataTables_scrollBody::-webkit-scrollbar {
    height: 10px;
    width: 6px;
    background-color: $scroll-color;
  }

  .dataTables_scrollBody::-webkit-scrollbar-thumb {
    background: $main-color;
  }
}
