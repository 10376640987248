* {
  font-family: 'Open Sans Light', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

/*

:root {
  --jquery-orange: orange;
  --jquery-orange-border: orange;
  --jquery-orange-button-hover: orange;
  --amigo-primary-color: #2f79fe;
  --amigo-secondary-color: #2f79fe;
  --amigo-primary-text-color: #6e6e6e;
  --amigo-secondary-text-color: #a7a7a7;
  --amigo-inverted-text-color: #fff;
  --amigo-body-bg-color: #d5d8dc;
  --amigo-text-color-dark: #333;
  --amigo-primary-hover-color: #2f79fe;
  --amigo-selection-border-color: #2f79fe;
  --amigo-primary-border-color: #dcdcdc;
  --amigo-secondary-border-color: #ddd;
  --amigo-shadow-border-color: #dedede;
  --amigo-primary-icon-color: #3d4d5d;
  --amigo-loading-svg-text-color:#333333;
  --amigo-primary-bg-color: #fff;
  --amigo-secondary-bg-color: #f4f4f4;
  --amigo-third-bg-color: #f0f2f5;
  --amigo-popup-bg-color: #e8eaed;
  --amigo-popup-footer-color: #e8eaed;
  --amigo-error-color: #ed5565;
  --amigo-viewer-error-color: #e81123;
  --amigo-disabled-color: #7e7e7e;
  --amigo-dialog-header-bg-color: #f8f8f8;
  --amigo-scrollbar-color: #c5c9d0;
  --amigo-scrollbar-hover-color: #a7a7a7;
  --amigo-question-background: #f3f3f3;
  --amigo-answer-bgcolor: #36ac47;
  --amigo-wronganswer-bgcolor: #db2828;
  --disabled-background: #e5e6ea;
  --disabled-button-border: #B5A9A9;
  --disabled-button-text: #B5A9A9;
  --jquery-modal-footer: #e8eaed;
  --jquery-modal-body: #f8f8f8;
} */

:root {
  --menu-dropdown-bg: #26282c;
  --menu-dropdown-text-color: #CCCDCF;
  --menu-dropdown-svg-color: #CCCDCF;
  --amigo-primary-text-color: #CCCDCF;
  --amigo-primary-score-color: #2f79fe;
  --base-body-bg-color: #191A1E;
  --amigo-creator-header-color: #191A1E;
  --modal-question-body-bg-color: #2E3138;
  --base-header-color: #191A1E;
  --base-button-hover-color: #595959;
  --amigo-body-bg-color: #191A1E;
  --amigo-primary-color: #2f79fe;
  --amigo-popup-footer-color: #282B32;
  --amigo-light-color: #282B32;
  --amigo-primary-bg-color: #fff;
  --amigo-answer-bgcolor: #36ac47;
  --amigo-wronganswer-bgcolor: #db2828;
  --amigo-viewer-error-color: #e81123;
  --question-body-bg-color: #2E3138;
  --question-item-bg-color: #282B32;
  --question-img-item-bg-color: #464b53;
  --amigo-dialog-header-bg-color: #2E3138;
  --menu-dropdown-bg: #26282c;
  --text-area-bg: #4b4e53;
  --amigo-disabled-color: #484c51;
  --amigo-disabled-text-color: #d6e5ea;
  --amigo-disabled-svg-color: #8e8e93;
  --assessment-item-color: #CCCDCF;
  --jquery-modal-footer: #4b4e53;
  --jquery-modal-body: #313339;
  --material-primary-color: #5491ff;
  --table-body-background: #34363d;
}
.select-description-type{
  width: 115px !important;
  height: 25px !important;
  margin-bottom: 15px !important;
  margin-left: 3px !important;
  margin-right: 5px;
  text-decoration: none !important;
}
.svd_container svd-pages-editor,
.sv_main.sv_default_css .sv_body,
.svd-designer-h-container {
  /* background-color: var(--amigo-third-bg-color) !important; */
  font-family: inherit !important
}

/*
.sv_main.sv_default_css .sv_body {
  padding: 0px !important;
} */

.svd_container {
  font-family: inherit !important
}

.sv_main.sv_default_css .sv_progress_bar {
  background-color: var(--amigo-primary-color) !important;
  margin-top: 0 !important
}

.svd-designer-tabbed-container__tab-header:hover {
  color: var(--amigo-primary-color) !important
}

.sv_main.sv_default_css .sv_body {
  background-color: var(--amigo-primary-bg-color);
  border-color: #f8f8f8 !important
}

.sv_main .sv_body {
  border-top: 0 solid #f8f8f8 !important
}

.floatright {
  float: right !important
}

.svd_page .description_editable,
.sv_page_title {
  display: none;
}

.svd_q_selected .title_editable .svda-title-editor-start,
.svd_q_selected .description_editable .svda-title-editor-start {
  visibility: visible !important;
}

.show-question-description,
.modal__content .sv_q_description,
#survey-preview .sv_q_description,
#AttemptContainer .sv_q_description,
#assessmentID .sv_q_description {
  /* display: block !important; */
  display: flex !important;
  /* flex-direction: row-reverse;
  justify-content: flex-end; */
  align-items: center;
  padding-left: 21px !important;
  padding-bottom: 10px;
}

.desc-preview-img {
  display: flex;
  width: 150px;
  height: 150px;
  margin-right: 10px;
  object-fit: contain;
}
.preview-modal-img{
  /* display: flex;
  width: 100px;
  height: 100px; */
  margin-right: 10px;
  margin-bottom: 1em;
  margin-left: 23px;
  /* object-fit: contain; */
}
.preview-modal-img>img{
  object-fit: contain;
  width: 150px;
  height: 60px;
}

.desc-image-input {
  display: none !important;
}

.add-description-image-btn {
    vertical-align: top;
    border: 1px solid lightgray;
    border-radius: 4px;
    text-align: center;
    padding: 0;
    width: 150px;
    height: 150px;
    padding-left: 0px !important;
    margin-left: 0px !important;
}
.add-image-svg-div{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.viewer-img-desc {
  padding-left: 3px;
}

.description-image {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.question-description-span {
  display: block !important;
}

.sv_q_description>span {
  color: var(--amigo-primary-text-color) !important;
}

.display-inline-block {
  display: inline-block !important;
}

.floatleft {
  float: left !important
}

@media(min-width:1540px) {
  .container {
    width: 100vw !important
  }
}

@media(min-width:992px) {
  .container {
    width: 970px
  }
}

@media(min-width:768px) {
  .container {
    width: 750px
  }
}

.sa-question__content {
  border-radius: 5px;
  border: 0 solid #e5e6e9;
  background-color: var(--question-body-bg-color) !important;
  border-left: 3px solid #2f79fe
}

.main-svg {
  background-color: transparent !important
}

.bgcolor {
  background-color: white !important
}

.svd_editors {
  padding: 0 !important;
  background-color: var(--amigo-body-bg-color) !important
}

question-actions .svda_question_action--separated {
  padding-left: 2em !important
}

.sv_main.sv_default_css .sv_header,
.bgcolor {
  background-color: transparent
}

.displaynone {
  display: none !important;
}

.d-block {
  display: block !important;
}

.navbar {
  display: none !important;
}

.svd-toolbar-button__image {
  display: none !important
}

.svd_container .btn-primary,
.btn-default,
.btn-secondary,
.sv_main.sv_default_css input[type="button"],
.sv_default_css button,
.modal__button,
.modal__button--defaul {
  color: var(--amigo-primary-color) !important;
  cursor: pointer !important;
  background-color: transparent !important;
  border-color: var(--amigo-primary-color) !important;
  border-radius: 4px !important;
  border: 1px solid !important;
  min-width: 78px !important;
  width: auto
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: 0 auto -webkit-focus-ring-color !important;
  outline-offset: 0 !important
}

.sv_main.sv_default_css input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]):focus {
  border: 0 solid var(--amigo-primary-color) !important
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  background-color: transparent !important;
  cursor: default !important;
  color: var(--amigo-disabled-color) !important
}

.svd_container:hover .btn-primary:hover,
.btn-default:hover,
.btn-secondary:hover,
.modal__button:hover,
.modal__button--defaul:hover,
.sv_main.sv_default_css input[type="button"]:hover,
.sv_default_css button:hover {
  background-color: var(--amigo-primary-color) !important;
  color: var(--amigo-primary-bg-color) !important;
  border-color: var(--amigo-body-bg-color) !important
}

.sv_main.sv_default_css .sv_select_wrapper::before {
  background-color: var(--amigo-primary-color) !important
}

.svd_custom_select:before {
  background-color: transparent !important
}

.svd-empty-message {
  color: var(--amigo-primary-color) !important
}

.select2-selection__arrow {
  background-color: var(--amigo-primary-color) !important
}

.sjs-cb-switch input:checked+.sjs-cb-switch_control {
  border-color: var(--amigo-primary-color) !important;
  background-color: white !important
}

.sjs-cb-switch input:checked+.sjs-cb-switch_control .sjs-cb-switch__button {
  border-color: var(--amigo-primary-color) !important;
  background-color: var(--amigo-primary-color) !important
}

.svd-main-background-color {
  background-color: var(--amigo-primary-color) !important
}

.svda-add-custom-item {
  color: var(--amigo-primary-color) !important;
  margin-left: 5px !important
}

.svd_container .svd-main-color,.text-descritption-span {
  color: var(--amigo-primary-text-color);
}

.svd_container .svd-primary-icon .svd-svg-icon,
.svd_container .svd-secondary-icon .svd-svg-icon {
  fill: var(--amigo-primary-color) !important
}

.svd_container .icon-gearactive .svd-svg-icon,
.svd_container .icon-dotsactive .svd-svg-icon {
  fill: var(--amigo-primary-color) !important
}

.svd_container .svd-tab.active>.svd-tab-text {
  color: var(--amigo-primary-color) !important;
  background-color: var(--amigo-primary-bg-color) !important
}

.sv_main.sv_default_css .sv_p_root>.sv_row:nth-child(2n) {
  background-color: var(--amigo-body-bg-color) !important
}

.svd_container .svd-svg-icon {
  fill: var(--amigo-primary-text-color) !important
}

.svd-designer-container--left-side {
  background-color: var(--amigo-primary-bg-color) !important
}

.sv_main.sv_main .sv-boolean__switch {
  background-color: var(--amigo-primary-color) !important
}

.sv_main.sv_default_css input[type="button"],
.sv_default_css button {
  background-color: transparent !important
}

.btn-primary {
  background-color: transparent !important;
  border-color: #2e6da4 !important
}

.svd_action,
.sa-table__btn,
.sv_complete_btn,
.sv_prev_btn,
.sv_next_btn {
  background-color: var(--amigo-primary-bg-color) !important;
  color: #fff !important;
  min-width: 70px !important;
  height: 30px !important;
  border-radius: 3px !important;
  cursor: pointer !important;
  padding: 0 0 !important;
  font-size: 13px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  outline: 0 !important;
  transition: all .3s ease !important
}

sa-table input {
  height: 30px !important;
  border: 0;
  border-bottom: .06em solid #d4d4d4;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  padding: .25em 1.5em .25em .87em;
  background: transparent
}

.svd-designer-tabbed-container__tab-header-active {
  border-bottom: 4px solid var(--amigo-primary-color) !important;
  border-bottom-color: var(--amigo-primary-color) !important
}

.svd-designer-container__header {
  color: var(--amigo-primary-color) !important
}

.svd-dark-bg-color {
  background-color: var(--amigo-primary-bg-color) !important
}

.sv_body {
  border: none !important
}

.svd-no-outline {
  border: 0 solid var(--amigo-primary-border-color) !important
}

.svd_toolbox_item:hover .svd-svg-icon {
  fill: var(--amigo-primary-color) !important
}

.svd_container.sv_default_css .svd_surveyjs_designer_container.svd_surveyjs_designer_container .sv_row .svd_question question-actions .svda_question_action .icon-actiondragelement .svd-svg-icon,
.svda-drag-handle .svd-primary-icon .svd-svg-icon {
  /* bottom: 3px !important; */
  width: 16px !important;
  height: 16px !important;
}

.svda-drag-handle {
  margin-top: 7px !important;
}

.sv_technical.svda-add-new-item.svd-primary-icon.svda-add-custom-item .svd-svg-icon {
  width: 16px !important;
  height: 16px !important;
}

.svd_toolbox_item:hover {
  color: var(--amigo-primary-color) !important
}

.svd_toolbox_item {
  padding-bottom: 15px !important;
  padding-top: 15px !important;
  margin-right: 0 !important;
  background: var(--amigo-primary-bg-color) !important;
  margin-left: 15px !important;
  margin-right: 15px !important
}

.svd-designer-container__header-title {
  font-size: 14px !important
}

.svd-designer-container__header {
  margin-bottom: 0 !important
}

.svda-question-actions {
  position: absolute !important;
  right: 15px !important;
  top: 0 !important;
  left: auto !important;
  line-height: 30px !important;
  min-width: 260px !important;
  padding-right: 15px !important;
  margin-right: 16px !important
}

.svd-icon-container {
  display: none !important
}

.svd-splitter {
  display: none !important
}

.sv_main .sv_header {
  display: none
}

.container- {
  margin: 20px !important
}

.svd_container {
  height: calc(100vh - 80px) !important
}

.svd_toolbar {
  margin: 5px 4px 0 0 !important;
  position: relative;
  right: 88px;
  display: none !important
}

@media screen and (max-width:1600px) {
  .positionabsolute {
    width: calc(100% - 290px) !important
  }
}

@media screen and (max-width:992px) {
  .positionabsolute {
    width: calc(100% - 30px) !important;
  }
}

@media screen and (max-width:600px) {
  .positionabsolute {
    width: calc(100% - 0) !important
  }
}

.svd-empty-message-container img,
.svd-empty-message-container {
  display: none !important
}

.paddingL5 {
  padding-left: 5px !important
}

.svd_questions_editor {
  padding-top: 0 !important
}

.sv-q-col-1 {
  border: 0 solid var(--amigo-primary-border-color) !important;
  margin-bottom: 10px !important;
  padding-top: 5px !important;
  margin-left: 23px !important;
  width: calc(100% - 39px) !important;
  border-radius: 5px !important
}

.sv_q_text_root {
  border-width: 0 !important;
  border-radius: 5px !important
}

.item_editable {
  width: calc(100% - 45px) !important;
  display: inline-block
}

.sv_main.sv_default_css .sv_container .sv_body .sv_p_root .sv_q input[type="radio"]:focus,
.sv_default_css .sv_container .sv_body .sv_p_root .sv_q input[type="checkbox"]:focus,
input[type=checkbox] {
  margin-left: 10px !important;
  margin: 4px 0 0;
  margin-top: 1px;
  line-height: 22px !important;
  background: #fdfdfd !important;
  background-color: #fffcfc !important;
  width: 14px !important;
  height: 14px !important;
  margin: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 1px solid var(--amigo-primary-text-color);
  box-shadow: none;
  font-size: 14px !important;
  text-align: center;
  line-height: 1em
}

.sv_main.sv_default_css .sv_container .sv_body .sv_p_root .sv_q input[type="radio"]:focus,
.sv_default_css .sv_container .sv_body .sv_p_root .sv_q input[type="checkbox"]:focus {
  outline: var(--amigo-primary-color) solid 1px !important;
  width: 15px !important;
  height: 15px !important
}

input[type='checkbox']:checked:after {
  content: '✔';
  color: var(--amigo-primary-color);
  position: relative;
  bottom: 3px
}

input[type=radio] {
  border-radius: 8px;
  border: 1px solid var(--amigo-primary-text-color);
  margin-left: 10px !important;
  margin: 4px 0 0;
  margin-top: 1px;
  line-height: normal;
  background: #fdfdfd !important;
  background-color: #fffcfc !important;
  width: 14px !important;
  height: 14px !important;
  margin: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  box-shadow: none;
  font-size: .8em;
  text-align: center;
  line-height: 1em
}

input[type='radio']:checked:after {
  content: '•';
  color: var(--amigo-primary-color);
  position: relative;
  bottom: 4px
}

.svda-drag-handle {
  float: right;
  /* position: absolute !important; */
  line-height: 1px;
}

.sa-table__action-container .sa-table__drag-button use {
  fill: var(--amigo-primary-color) !important
}

.sv_main.sv_default_css input[type="button"],
.sv_default_css button {
  background-color: var(--amigo-primary-color)
}

.sa-commercial {
  display: none !important
}

.svd-vertical-container__cell-content {
  padding: 10px 0 20px 0;
  background-color: var(--amigo-body-bg-color) !important
}

.svd-survey-placeholder {
  background-color: var(--amigo-body-bg-color) !important;
}

.svd_container .svd_content {
  height: calc(90vh - 30px) !important;
  padding: 0 !important
}

.sv_main .sv_container .sv_body .sv_p_root>.sv_row {
  padding: 0;
  margin-top: 0 !important;
  /* background-color: var(--amigo-body-bg-color) !important */
}

.sv_main .sv_p_root>.sv_row {
  border: 0 !important
}

.backbluecircle {
  background-color: var(--amigo-primary-color);
  border-radius: 15px;
  height: 30px;
  width: 30px;
  float: left;
  position: relative;
  bottom: 7px;
  padding: 6px 6px 6px 8px
}

/* .sv_main .sv_container .sv_body {
    padding: 0!important
} */
.svd-designer-container--left-side>.svd-designer-container>.svd-vertical-container {
  background-color: var(--amigo-primary-bg-color) !important
}

.svd-designer-container__header {
  text-align: left !important
}

.sv_q_text_root,
.sv_qcbc .sv_qbln {
  margin-left: 23px !important;
  width: calc(100% - 39px) !important
}

.svd_container.sv_default_css .svd_surveyjs_designer_container.svd_surveyjs_designer_container .sv_row .svd_question {
  margin-top: 0 !important
}

.svd_container .svd_questions_editor .svd_question:hover {
  border-color: var(--amigo-primary-color) !important
}

.icon-actioneditelement+span {
  display: none !important
}

::-webkit-scrollbar {
  width: 5px !important;
    height: 5px !important;
    opacity: 0 !important;
}

::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #b9b9bc !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #b9b9bc;
}

div:hover>::-webkit-scrollbar {
  opacity: 1
}

.svd_container .svd_content .svd_custom_select:before,
.svd_container .modal-dialog .svd_custom_select:before {
  border-radius: 4px !important
}

.svd_container .svd_content .svd-toolbar-dropdown .svd_custom_select .svd-toolbar-dropdown__select {
  border-radius: 4px !important
}

.svd_container svd-pages-editor .svd-page {
  border-radius: 4px 4px 0 0 !important
}

.svd-page {
  border-width: 0 !important
}

.sv_progress {
  display: none !important
}

.sv_nav {
  background: white !important;
  margin-bottom: 30px !important
}

#svd-test-simulator,
#svd-test-simulator-orientation {
  display: none !important
}

.sv_body_empty,
.sv_completed_page {
  display: none !important
}

.svd_container,
.svd_container:hover {
  color: var(--amigo-primary-text-color) !important
}

.svd_container:active {
  color: var(--amigo-primary-color) !important
}

.sa-question__title--draggable:after {
  background-image: url("data:image/svg+xml,%3Csvgversion='1.1'xmlns='http://www.w3.org/2000/svg'viewBox='001616'%3E%3Cg%3E%3Cpolygonstyle='fill:%235b69f8'points='13,512,613,79,79,310,411,38,05,36,47,37,73,74,63,50,83,114,103,97,97,136,125,138,1611,1310,129,139,913,912,1013,1116,8'/%3E%3C/g%3E%3C/svg%3E") !important
}

.modebar-container {
  display: none !important
}

#surveyAnalyticsContainer>.sa-visualizer__toolbar .sa-panel__header>.sa-toolbar>.sa-toolbar__button,
span.sa-toolbar__button {
  display: none !important
}

.svd_toolbox_item.svd-light-border-color.svd_toolbox_item_icon-checkbox,
.svd_toolbox_item.svd-light-border-color.svd_toolbox_item_icon-boolean {
  border-bottom-width: 0 !important
}

.svd-designer-h-container svd-toolbar {
  position: fixed;
  top: 0;
  right: 4px
}

.svd-vertical-container__row:first-child {
  position: fixed !important;
  top: 0 !important;
  background: var(--amigo-primary-bg-color) !important;
  height: 50px !important;
  width: 100% !important;
  left: 0 !important;
  z-index: 1999 !important;
  border-bottom: 1px solid var(--amigo-primary-border-color)
}

.svd-vertical-container__cell-content-holder {
  height: 100% !important
}

.svd-page-actions-container {
  display: none !important
}

.svd_selected_page {
  color: var(--amigo-primary-color) !important;
  font-size: 14px !important
}

#scrollableDiv {
  border-radius: 3px !important;
  background-color: var(--amigo-body-bg-color) !important
}

.svd-pages {
  border-bottom-width: 0;
  border-radius: 5px 5px 0 0
}

.sv_qstn .sv-q-col-1,
.sv-question .sv-q-col-1,
.sv_q_text_root {
  background: var(--question-item-bg-color);
  /*!important;*/
  padding-left: 16px
}

.item_editable>.sv-string-viewer {
  /* width: calc(100% - 68px); */
  width: calc(100% - 71px);
  display: inline-block;
  color: var(--amigo-primary-text-color) !important;
}

.sv_q_title title_editable {
  padding-left: 5px !important;
}

/* .sv_q_title
{
color: red !important;
} */
.svd_container svd-pages-editor .svd-page {
  overflow: hidden;
  vertical-align: bottom;
  letter-spacing: normal;
  cursor: pointer;
  height: 40px !important;
  font-weight: bold;
  border-width: 0;
  border-width: 1px;
  border-style: solid;
  padding: 10px 20px !important;
  border-bottom: 0
}

.svda_question_action {
  padding-top: 2px
}

.svda_question_action,
.svd-main-color {
  vertical-align: text-bottom !important;
  position: relative !important
}

/* .title_editable .sv-title-actions {
    width: 60%!important;
} */

.svda_action_separator {
  margin-left: 15px !important;
  margin-top: -8px !important
}

.svd-svg-icon {
  height: 24px !important;
  width: 16px !important
}

.svd_q_selected>.svda-question-actions {
  margin-left: 0 !important;
  margin-top: 15px !important
}

.svda_question_action:nth-child(1).svd-main-color {
  padding-right: 0 !important
}

.sv_q,
.sv_qstn {
  width: 50% !important;
  margin: 0 auto !important
}

.svd_survey_designer>.sv_q,
.sv_qstn {
  width: 100% !important
}

.sv-title-actions,
title-editor {
  max-width: 100% !important
}

/* .svd_surveyjs_designer_container .title_editable .sv-title-actions
{
  width: 76%;
}
.comment-edit-icon
{
  width: 88% !important;
} */
/* .sv-title-actions__title
{ */
/* display: block; */
/* width: 78%; */
/* display: flow-root; */
/* } */
/* .sv-title-actions
{
  display: flex;
} */

.svd_q_design_border:nth-child(1)>div>h5 {
  text-align: left !important;
  font-size: 14px !important;
  color: var(--amigo-primary-text-color) !important;
}

/* .svd_q_design_border:nth-child(1)>div>h5 span {
  vertical-align: top !important;
} */

.svda-title-editor-start-button.edit-survey-name {
  position: relative;
  bottom: 3px;
}

.svd_q_selected .show-edit-icon {
  /* display: block !important; */
  visibility: visible !important;
  /* left: 10px !important; */
  bottom: 2px !important;
}

.svda-title-editor-content input {
  width: 100% !important
}

.svd_q_design_border>div .sv_qcbc {
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important
}

.sv_qstn:nth-child(1)>div>h5 {
  font-size: 32px;
  color: var(--amigo-primary-color) !important;
  padding-left: 21px;
  padding-bottom: 10px
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_title {
  font-weight: normal !important;
}

.sv_qcbc {
  border: 0 solid var(--amigo-shadow-border-color) !important
}

.surveyContainerInPopup>.sv_qcbc {
  width: 100% !important
}

.sv_qcbc .sv-q-col-1 {
  margin-bottom: 20px !important
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q {
  padding: 20px !important;
  margin-bottom: 20px !important;
  background: var(--question-body-bg-color) !important;
  border-radius: 6PX;
  width: 80% !important
}

.survey-container {
  height: 500px !important;
  background-color: var(--amigo-body-bg-color) !important;
  padding-top: 0 !important
}

.sv_q_radiogroup.sv-q-col-1.checked,
.sv_q_checkbox.sv-q-col-1.checked {
  background: var(--amigo-primary-color);
  color: white !important
}

.survey-container .sv_main .sv_container .sv_body .sv_nav {
  position: absolute !important;
  right: 10% !important;
  background: transparent !important;
  padding-right: 12px !important;
}

.sv_main .sv_container .sv_body .sv_nav {
  padding-bottom: 22px !important;
}

.sv_qcbx>legend {
  display: none !important
}

.svd-toolbar-dropdown .svd-toolbar-dropdown__select {
  display: inline-block;
  padding: 0 6px !important;
  border: 1px solid #ccc;
  height: 34px;
  min-width: 100px
}

svd-pages-editor.positionabsolute {
  display: none !important
}

.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
  background-color: transparent !important;
  border: 0px transparent !important;
  color: var(--amigo-viewer-error-color) !important;
  border-radius: 4px !important;
  min-height: 38px !important;
  padding: 9px !important;
  margin: 0 16px 20px 24px !important
}

.sv_q_required_text {
  color: var(--amigo-viewer-error-color) !important;
  font-size: 24px;
  line-height: 1px;
  vertical-align: middle;
  font-weight: normal;
}

.svd_container a {
  color: var(--amigo-primary-text-color) !important
}

.sa-question__title--draggable::after {
  display: none !important
}

.sa-question__title {
  font-family: 'Century Gothic', Arial, sans-serif !important;
  /* color: #2f79fe !important; */
  font-weight: 400 !important;
  font-size: 15px !important
}

.js-plotly-plot .plotly .main-svg {
  position: relative !important;
  pointer-events: none
}

.js-plotly-plot .plotly .main-svg .point path {
  fill: var(--amigo-primary-color) !important;
}

.js-plotly-plot {
  display: flex;
  justify-content: center
}

.sa-panel__content {
  background-color: white
}

.plot-container {
  width: 100%
}

.main-svg {
  width: 100% !important
}

.sa-question-custom-width {
  width: 100% !important
}

.sa-visualizer__toolbar {
  display: none
}

.width-100 {
  width: 100% !important;
}

.h-50px {
  height: 50px;
  background-color: var(--base-header-color) !important;
}

.h-100px {
  height: 100px !important;
}

.center-align-body {
  height: 100% !important;
  width: 100% !important
}

.height-100 {
  height: 100% !important
}

.nsewdrag {
  pointer-events: none !important
}

.ewdrag {
  pointer-events: none !important
}

.wdrag {
  pointer-events: none !important
}

.sedrag {
  pointer-events: none !important
}

.drag {
  pointer-events: none !important
}

.zerolinelayer .crisp {
  stroke: none !important
}

.overplot .crisp {
  stroke: none !important
}

.ytick {
  font-weight: bold !important;
}

/*Analytics color css*/
.point>text,
.ytick>text,
.xtick>text {
  fill: var(--amigo-primary-text-color) !important;
}

.btn-green {
  background-color: var(--amigo-primary-color);
  color: #fff;
  border-radius: 3px
}

.btn-green:focus,
.btn-green:hover {
  background-color: var(--amigo-primary-color);
  color: #fff
}

.panel-footer {
  padding: 0 15px;
  border: 0;
  text-align: right;
  background-color: #fff
}

.builder-page__modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0
}

.modal {
  font-family: 'Century Gothic', Arial, sans-serif !important;
  background: rgba(0, 0, 0, 0.3);
  top: 0px !important
}

.modal-open .modal {
  overflow-y: hidden !important;
}

.modal__dialog {
  position: relative;
  max-width: 600px;
  margin: 76px auto
}

.modal__content {
  position: relative;
  background: var(--amigo-body-bg-color) !important;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  outline: 0;
  box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
  overflow: hidden;
  margin-block: 19%;
  /* height: 400px !important; */
}

.modal__header {
  font-size: 18px
}

.modal__button {
  display: inline-block;
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  min-width: 78px;
  color: var(--amigo-primary-color) !important;
  cursor: pointer !important;
  background-color: transparent !important;
  border-color: var(--amigo-primary-color) !important;
  border-radius: 3px !important;
  border: 1px solid !important
}

.modal__button--default {
  color: #333;
  background-color: #fff;
  border-color: #ccc
}

.modal__body {
  font-size: 14px;
  padding: 5px 0 0 0;
  overflow: auto;
  background: var(--amigo-body-bg-color) !important;
  max-height: 50vh !important;
  width: 100%;
}

.bgcolor .sv_q .sv_qstn .disable-element {
  background: var(--modal-question-body-bg-color) !important;
}

.modal__footer {
  padding: 15px;
  text-align: right;
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  background: var(--amigo-light-color) !important;
  /* position:absolute; */
  bottom: 0px;
  right: 0px;
  width: 100%;
}

.modal__dialog--animated {
  -webkit-animation-duration: .2s;
  animation-duration: .2s
}

.model__icon--content {
  width: 30px;
  height: 30px;
  fill: var(--base-color);
  margin-right: 10px;
  border-radius: 50%;
  background: #d6e3f9;
  display: inline-grid;
  place-items: center;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

.modal__dialog--fade-in-down {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown
}

#surveyContainerInPopup>.sv_nav {
  display: none !important
}

.btn .caret {
  margin-left: 4px !important
}

.FormHeader {
  z-index: 1;
  position: absolute;
  top: 0;
  background: var(--amigo-body-bg-color);
}

.FormName {
  top: 63px;
  right: 403px;
  color: blue;
  border-top: 2px solid;
  border-right: 2px solid;
  border-left: 2px solid;
  min-width: 150px;
  border-color: var(--base-header-color);
  background: var(--base-header-color);
  border-radius: 7px 7px 0 0;
}

.FormNamePosition {
  position: fixed;
  top: 15px;
  z-index: 2000;
  left: 10px
}

#creatorAddDiv {
  background-color: var(--amigo-body-bg-color) !important;
  position: relative;
  width: 100%;
  z-index: 1;
  top: 48px !important;
  height: 52px;
  display: flex;
  justify-content: space-between;
}

.divRightButtons {
  position: fixed;
  top: 10px;
  z-index: 2000;
  right: 0;
  display: flex;
  align-items: center;
}

.AddQuestionOption {
  top: 5px !important;
  left: 0 !important;
  padding: 5px 12px !important;
  width: 160px;
  font-family: 'Century Gothic', Arial, sans-serif !important;
  /* z-index: 2000; */
  display: inline-block !important;
}

.AddtopClose {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  padding: 5px 10px !important;
  width: 200px;
  font-family: 'Century Gothic', Arial, sans-serif !important;
  z-index: 2000;
  height: 65px !important
}

.svgFormName {
  height: 16px;
  width: 16px;
  fill: var(--menu-dropdown-svg-color) !important
}

.svgFormIcon {
  height: 16px;
  width: 16px;
  fill: var(--amigo-primary-color) !important
}

.svgFormIconwhite {
  height: 14px;
  width: 14px;
  fill: var(--amigo-primary-bg-color) !important
}

.FormNameLabel {
  padding-left: 10px;
  position: relative !important;
  bottom: 2px;
  color: var(--amigo-primary-color);
  font-weight: 400 !important;
  font-size: 13px
}

.backgraysquare:hover {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: 0 10px 0 0;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--base-button-hover-color) !important;
}

.backgraysquare {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: 0 10px 0 0;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  padding: 7px
}

.marginr20 {
  margin-right: 20px
}
#pdf-export-btn{
  width: 100%;
}
.pdf-export {
  margin: 0 10px 0 0;
  cursor: pointer;
  padding: 7px;
  font-size: 13px;
  color: #2f79fe;
  font-weight: 600;
  float: right;

}
.csv-export{
  margin: 0 10px 0 0;
  cursor: pointer;
  padding: 7px 7px 7px 0px;
  font-size: 13px;
  color: #2f79fe;
  font-weight: 600;
}
.parent-cloned-formname{
  transform: translateX(40%);
  position: relative !important;
}
.cloned-formname-type{
  color: #5e5e5e !important;
  font-size: 16px !important;
  margin-left: 10%;
  margin-top: 10px;
  margin-bottom: 25px;
}
.logo-img-container {
  background-color: transparent !important;
  width: 260px;
  height: 60px;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-img-container>svg {
  height: 60px;
  width: 260px;
  fill: #2f79fe;
  cursor: pointer;
  margin: 0px;
}

.logo-img-container>img {
  height: 60px;
  width: 260px;
  cursor: pointer;
}
.cloned-formname{
  font-size: 18px !important;
  top: 5px !important;
  color: #5e5e5e !important;
  font-weight: 600 !important;
}
.viewer-page-export{
  margin-left: 12% !important;
  margin: 0 10px 0 0;
  cursor: pointer;
  font-size: 13px;
  color: #2f79fe;
  font-weight: 600;
}
.viewer-score-parent{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90% !important;
  align-items: center;
}
.AddCloseOption {
  padding: 5px 10px !important;
  min-width: 78px;
  font-family: 'Century Gothic', Arial, sans-serif !important;
  z-index: 2000;
  background-color: white !important;
  height: 30px;
  outline: none !important;
  font-weight: 700 !important
}

.AddShareOption {
  float: right;
  position: relative;
  right: 10px;
  top: 10px;
  padding: 4px 10px 7px !important;
  min-width: 78px;
  font-family: 'Century Gothic', Arial, sans-serif !important;
  z-index: 2000;
  background-color: white !important;
  height: 30px;
  outline: none !important;
  font-weight: 400 !important
}

.AddShareOption :active :visited :checked,
.AddCloseOption :active :visited :checked {
  outline: none !important
}

#svd-close {
  display: none !important
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 4px dashed !important;
  border-bottom: 0 dashed !important;
  border-bottom: 4px solid\9
}

.caret {
  color: white;
}

.mat-button {
  background-color: #2f79fe !important
}

.mat-menu-panel {
  min-width: 200px !important;
}

.mat-menu-item {
  font-family: 'Century Gothic', Arial, sans-serif !important
}



.dropdown-content {
  position: relative;
  line-height: 21px;
}

.moveright {
  float: right !important;
  margin-right: 14px
}

.dropdown-menu {
  position: absolute !important;
  transform: translate3d(10px, 48px, 0px) !important;
  top: -7px !important;
  left: 0 !important;
  height: fit-content !important;
  will-change: transform !important;
  font-family: 'Century Gothic', Arial, sans-serif !important;
  color: var(--amigo-primary-text-color) !important;
  padding: 3px !important;
  padding-top: 6px !important
}

.dropdown-menu li:hover {
  background-color: var(--amigo-popup-bg-color) !important;
  margin: 2px 3px 3px 0;
  border-radius: 3px
}

.dropdown-menu li {
  font-size: 12px !important;
  font-weight: bold !important;
  margin: 2px 3px 3px 0;
  height: 30px;
  align-items: center;
  display: flex
}

.dropdown-menu::before {
  position: absolute;
  top: -7px;
  left: 8px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid var(--amigo-text-color-dark);
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, .2);
  content: ""
}

.dropdown-menu::after {
  position: absolute;
  top: -6px;
  left: 9px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid var(--amigo-primary-bg-color);
  border-left: 6px solid transparent;
  content: ""
}

.btn {
  font-size: 13px !important
}

.dropdown-toggle {
  background-color: var(--amigo-primary-color) !important;
  color: white !important;
  font-size: 13px;
  height: 30px;
  padding: 1px 12px 3px 12px !important;
  margin-top: 2px
}

.question-content-toggle {
  width: 160px !important;
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
  color: var(--amigo-primary-text-color) !important;
  text-decoration: none !important;
  margin: 0 !important;
  background-color: var(--amigo-popup-bg-color) !important;
  border-radius: 3px !important
}

.addQuestionsvg {
  padding-top: 4px;
  fill: var(--menu-dropdown-svg-color);
}

.preview-icon {
  height: 16px;
  width: 16px;
  padding: 0px;
  margin: 0px;
  fill: var(--amigo-primary-color)
}

.addQuestionsvgwhite {
  padding-top: 4px;
  fill: #fff !important;
  padding-right: 4px;
  margin-right: 2px;
  margin-top: 1px;
  display: inline-block;
  vertical-align: top;
  width: 16px;
  height: 16px;
}

.height2px {
  height: 2px !important
}

.sv_q_num {
  color: var(--amigo-primary-color) !important
}

.container- h2 {
  display: none
}

.container- {
  width: 100vw;
  margin: 0 auto !important;
  min-width: 800px;
  position: fixed;
  top: 100px;
  left: 0;
  RIGHT: 0;
  height: 100vh
}

.containerPreview {
  min-width: 400px !important;
  width: 100vw !important;
  margin: 0 auto !important
}

.containeranalytics {
  position: relative !important;
  top: 0 !important;
  min-height: unset !important;
  width: 100% !important
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  overflow: auto;
  background-color: var(--amigo-body-bg-color) !important;
  height: calc(100% - 50px) !important;

}

#surveyElement {
  pointer-events: none
}

.pointer-surveyElement {
  pointer-events: unset !important;
}

.pointer-event {
  pointer-events: unset !important;
}

#AttemptContainer survey div #surveyElement {
  pointer-events: all !important
}

#AttemptContainer {
  background-color: var(--amigo-body-bg-color) !important;
  width: 100% !important;
  padding-top: 10px;
}

.svd_q_selected {
  border-left: 5px solid var(--amigo-primary-color) !important
}

.svd_container a:focus,
.svd_container div:focus,
.svd_container span:focus,
.svd_container select:focus,
.svd_container tr:focus,
.svd_container input[type="checkbox"]:focus+span.checkmark {
  outline-color: var(--amigo-primary-color) !important;
  outline-style: none !important;
  outline-width: 0 !important;
  outline-offset: 0 !important
}

.PreviewtopDiv {
  height: 50px;
  width: 100%;
  background-color: var(--amigo-primary-bg-color);
  border-bottom: 1px solid var(--amigo-primary-border-color);
  padding-top: 4px !important;
  z-index: 100
}

.PreviewtopBtn {
  right: 0 !important;
  top: 10px !important;
  position: fixed !important;
  font-family: 'Century Gothic', Arial, sans-serif !important;
  ;
  z-index: 2000;
  background-color: var(--base-header-color) !important;
  height: 30px
}

/* .sa-visualizer__footer {
    display: none!important
} */

.btn-container-top-header {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important
}

.btn-top-header {
  height: 50px !important;
  position: relative !important
}

.content-container {
  height: calc(100% - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  padding-left: 6px;
  /* padding-top: 9px; */
  background-color: var(--amigo-body-bg-color) !important
}

.container-first-layer {
  position: relative;
  height: 100%;
  width: 100%
}

.container-sec-layer {
  position: absolute !important;
  top: 0
}

#surveyquestioneditorwindow .modal-dialog .modal-content .modal-header {
  padding: 0 !important;
  border-bottom: 1px solid var(--amigo-body-bg-color) !important;
  background: var(--amigo-body-bg-color);
  font-size: 10px !important
}

.svd_container.sv_default_css .modal .modal-dialog .modal-content .modal-footer {
  border-top: 1px solid var(--amigo-body-bg-color) !important;
}

#surveyquestioneditorwindow .modal-dialog .modal-content .modal-body {
  background: var(--amigo-body-bg-color) !important;
  max-height: 50vh !important;
  width: 100%;
  margin: 0 auto;
  overflow: auto;
}

#surveyquestioneditorwindow .modal-dialog .modal-content .modal-footer {
  background: var(--amigo-popup-footer-color)
}

svd-property-editor .sv_main .sv_container .sv_body .sv_p_root .sv_q {
  padding: 15px 5px 5px 5px !important;
  margin-bottom: 0 !important;
  background: var(--question-body-bg-color) !important;
  /* background: var(--modal-question-body-bg-color) !important; */
  border-radius: 6PX;
  width: 100% !important
}

.svd-property-editor-survey-page .sv_row div .sv_q div .sv_qcbc .sv_q_radiogroup.sv-q-col-1.checked,
.svd-property-editor-survey-page .sv_row div .sv_q div .sv_qcbc .sv_q_checkbox.sv-q-col-1.checked {
  background: var(--amigo-answer-bgcolor) !important;
  color: white !important
}

.svd-property-editor-survey-page .sv_row div .sv_q div .sv_qcbc .sv_q_checkbox.sv-q-col-1.checked input[type='checkbox']:checked:after {
  bottom: 3px !important;
  color: var(--amigo-answer-bgcolor)
}

.svd-property-editor-survey-page .sv_row div .sv_q div .sv_qcbc .sv_q_radiogroup.sv-q-col-1.checked input[type='radio']:checked:after {
  content: '•';
  color: var(--amigo-answer-bgcolor);
  position: relative;
  bottom: 4px !important;
  left: 0
}

svd-property-editor .btn.sv-btn.btn-sm {
  display: none
}

input.form-control.svd-control.svd_editor_control.svd-focusable {
  width: 87.56% !important;
  float: right;
  border-radius: 5px
}

.bgRed {
  background-color: var(--amigo-wronganswer-bgcolor) !important;
  color: white
}

.bgGreen {
  background-color: var(--amigo-answer-bgcolor) !important;
  color: white
}

.bgGreen .sv_q_checkbox_label input[type='checkbox']:checked:after,
.bgGreen .sv_q_radiogroup_label input[type='radio']:checked:after {
  color: var(--amigo-answer-bgcolor) !important
}

.bgRed .sv_q_checkbox_label input[type='checkbox']:checked:after,
.bgRed .sv_q_radiogroup_label input[type='radio']:checked:after {
  color: var(--amigo-wronganswer-bgcolor) !important
}

#assessmentID {
  width: 100% !important;
  top: 50px !important;
  height: calc(100% - 50px) !important;
  padding-top: 10px !important;
}
#previewID .scoreHeader{
  width: 80%;
  padding: 5px 12px 5px 5px;
}
#previewID .export-btn-parent{
  margin-right: 5px;
}
.scoreHeader {
  color: var(--amigo-primary-text-color) !important;
  text-align: right;
  padding: 5px;
  width: 88%;
  margin: 0 auto;
  min-width: 300px;
  right: 5px !important;
}

.scoreHeaderAnalytics {
  color: var(--amigo-primary-text-color) !important;
  text-align: right;
  min-width: 300px;
  position: relative;
  right: 5px;
}

.scoreParent {
  width: 100%;
  background: var(--amigo-body-bg-color) !important;
  top: 20px;
  position: relative;
  z-index: 999;
  padding-top: 15px
}

.scoreParentothers {
  width: 100%;
  background: var(--amigo-body-bg-color) !important;
  top: 0;
  position: relative;
  z-index: 999;
  padding-top: 10px;
}

.analytics-score-header {
  position: absolute !important;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-inline: 15px;
  z-index: 0 !important;
  padding-top: 10px !important;
  right: 40px;
}
.marks-parent-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90% !important;
}
.creator-pdf-export{
  margin-left: 12% !important;
  margin: 0 10px 0 0;
  cursor: pointer;
  font-size: 13px;
  color: #2f79fe;
  font-weight: 600;
}
.creator-score{
  margin: 0px !important;
}

#SurveyCreatorContainer {
  padding-top: 7px !important
}

.modal-header .close,
.modal-header .close:hover,
.modal-header .close:active {
  color: var(--amigo-text-color-dark) !important;
  border: 2px solid var(--amigo-text-color-dark) !important;
  width: 20px !important;
  padding: 0;
  height: 20px;
  opacity: 1 !important;
  min-width: 20px !important;
  background: transparent !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 0 !important
}

#surveyquestioneditorwindow .modal-dialog {
  margin: 8vh auto !important
}

#surveyquestioneditorwindow {
  overflow: auto !important;
}

#surveyquestioneditorwindow .modal-dialog .modal-content .modal-header .modal-title {
  font-size: 14px !important;
  font-weight: 700 !important
}

.modal-content {
  margin-top: 20%;
}

.divHeaderEditor {
  width: 100%;
  height: 74px;
  background: var(--amigo-dialog-header-bg-color);
  padding: 6px 6px 6px 13px;
  display: flex;
  align-items: center
}

.spanHeaderEditor {
  font-size: 18px;
  font-weight: bold;
  /* color: var(--primary-text-color, #676a6c); */
  color: var(--amigo-primary-text-color);
}

/* #surveyContainerInPopup {
  pointer-events: none !important
} */
.disable-element,
.disable-image-div {
  pointer-events: none !important;
}

.answerkeysvg {
  fill: var(--amigo-primary-color);
  width: 16px;
  height: 16px;
  margin-block: 6px;
}

#surveyCreatorContainer .svd_container:active {
  color: var(--amigo-primary-text-color) !important
}

.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .sv_p_root>.sv_row .sv_q,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .sv_p_root>.sv_row .sv_qstn,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .svd-property-editor-survey-page>.sv_row .sv_q,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .svd-property-editor-survey-page>.sv_row .sv_qstn,
.svd_container survey-widget .sv_main .svd-property-editor-survey .sv_p_root>.sv_row .sv_q,
.svd_container survey-widget .sv_main .svd-property-editor-survey .sv_p_root>.sv_row .sv_qstn,
.svd_container survey-widget .sv_main .svd-property-editor-survey .svd-property-editor-survey-page>.sv_row .sv_q,
.svd_container survey-widget .sv_main .svd-property-editor-survey .svd-property-editor-survey-page>.sv_row .sv_qstn {
  padding: 17px !important;
  padding-top: 5px !important;
  border-radius: 0px !important;
}

.sv_main.sv_default_css .sv_container {
  background: var(--base-body-bg-color) !important;
}


#modalSurveyLabel {
  height: 70px;
  /* background: var(--amigo-dialog-header-bg-color); */
  padding: 0 15px;
  margin: 0;
  display: flex;
  border-radius: 3px;
  align-items: center
}

#modalSurveyLabel span {
  padding-left: 0px;
  padding-top: 2px;
  font-size: 18px;
  font-weight: bold;
  color: var(--amigo-primary-text-color) !important;
}

.dropdown-menu>li>a {
  display: flex !important;
  width: 100%
}

.dropdown-text {
  font-size: 12px !important;
  padding: 0 0 0 10px;
}

/*caret as background image*/
.typeselectdropdown {
  position: relative !important;
  top: 7px;
  border: 1px !important;
  appearance: auto !important;
  background: var(--question-item-bg-color) !important;
  color: var(--amigo-primary-text-color) !important;


  /* -webkit-appearance: none!important;
  -moz-appearance: none!important;
  background-image: url("data:image/svg+xml;utf8,<svg fill='var(--menu-dropdown-svg-color)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  background-repeat: no-repeat!important;
  background-position-x: 100%!important;
  padding-right:3rem !important; */
}

.typeselectdropdown>option,.sa-table__entries select>option,.sa-table__show-column>option {
  background: var(--question-item-bg-color) !important;
}

.img-question-label {
  display: grid !important;
  place-items: center !important;
  line-height: 1.7 !important;
}

.comment-question-dropdown {
  line-height: 1 !important;
  right: 25px;
  bottom: 5px;
  color: var(--amigo-primary-text-color) !important;

}

span:has(.comment-question-dropdown) {
  background: var(--question-item-bg-color) !important;
  pointer-events: none !important;
  width: 100px !important;
  height: calc(2em + 1px) !important;
}

span:has(.img-question-label) {
  background: var(--question-item-bg-color) !important;
  color: var(--amigo-primary-text-color) !important;
  pointer-events: none !important;
  width: 100px !important;
  height: calc(2em + 1px) !important;
}

.svd-property-editor-survey-page .sv_row div .sv_q div .sv_qcbc .sv_q_radiogroup.sv-q-col-1.checked input[type=radio]:checked:after:focus {
  bottom: 4px !important
}

.svd-popup-editor-tab-data div label.svd-control-label {
  float: left;
  margin-top: 8px
}

.sv_q_radiogroup_control_item {
  line-height: 22px !important;
}

.sv_q .sv_qstn .svd-survey-nopadding {
  border-radius: 0px !important;
}

.sv_main .sv_p_root .sv_row .sv_q.sv_qstn:first-child:last-child {
  border-radius: 3px !important;
}

.parent-tab-header {
  width: 80% !important;
  margin-left: 10% !important;
}

.sv_row {
  background: var(--amigo-body-bg-color) !important;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_text_root,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control,
.sv_main .sv_container .sv_body .sv_p_root .sv_q input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]),
.sv_main .sv_container .sv_body .sv_p_root,
.sv_main .sv_container .sv_body .sv_p_root .sv_q textarea {
  width: calc(100% - 36px) !important;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_other input,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control,
.sv_main .sv_container .sv_body .sv_p_root .sv_q input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]),
.sv_main .sv_container .sv_body .sv_p_root {
  width: 100% !important;
}

/* .sv_main .sv_container .sv_body .sv_p_root .sv_q_title
{
  display: flex !important;
  width: calc(100% - 54px) !important;
} */
.sa-visualizer__content.sa-panel__content.sa-grid.muuri {
  background-color: var(--amigo-body-bg-color) !important;
}

/* .sa-grid
 {
  position: unset !important;
 } */
.sv_body {
  margin-bottom: 20px !important;
  background-color: var(--amigo-body-bg-color) !important;
}

/* .svda-content
{
  width: calc(100% + 600px) !important;
} */
.btn-red {
  background-color: var(--amigo-viewer-error-color) !important;
  border: 1px solid var(--amigo-viewer-error-color) !important;
  color: #fff !important;
  outline: none !important;
  box-shadow: none !important;
}

.btn-red:hover {
  background-color: var(--amigo-viewer-error-color) !important;
  color: white !important;
}

#displayscore {
  float: right;
  margin-right: 10.5%;
  padding-top: 15px !important;
  padding-right: 12px;
  color: var(--amigo-primary-text-color);
}

.form-group {
  margin-bottom: 0px !important;
}

.disableSaveSvg {
  fill: var(--amigo-disabled-svg-color) !important;
}

/* .title_editable .sv-title-actions
{
    width: calc(100% - 280px)!important;
} */
.min-width-100 {
  min-width: 100px !important;
}

/* .xyz {
  display: block !important;
} */

.input-update {
  min-width: calc(100% + 100px) !important;
  padding-left: 0em !important;
}

/* .input-update {
  min-width: calc(100% + 280px)!important;
  max-width: calc(100% + 280px)!important;
  width: calc(100% + 280px)!important;
} */


/* @media (min-width:600px)  { .input-update
    {
      min-width: 230px !important;
      max-width: 230px !important;
      width: 230px!important;
    } }
  @media (min-width:801px)  { .input-update
    {
      min-width: 270px !important;
      max-width: 270px !important;
      width: 270px!important;
    } }
  @media (min-width:1025px) { .input-update
    {
      min-width: 400px !important;
      max-width: 400px !important;
      width: 400px !important;
    }}
  @media (min-width:1281px) { .input-update
    {
      min-width: 500px !important;
      max-width: 500px !important;
      width: 500px !important;
    } } */
.descriptive__modal__body {
  padding-block: 15px !important;
  overflow: auto;
  background: var(--amigo-body-bg-color) !important;
}

#descriptive_cancel_btn {
  margin-right: 8px;
}

.appended_btn {
  color: var(--amigo-primary-color) !important;
  font-weight: normal !important;
  font-size: 14px !important;
}

/* .creator-appended-btn
{
  padding-right: 14px;
} */
.appended_btn:hover {
  cursor: pointer;
}

.question-statement {
  font-size: 14px;
  /* margin-inline: 4px; */
  font-weight: bold;
  color: var(--amigo-primary-text-color);
}

.question_statement {
  padding: 4px 13px;
  font-size: 14px;
  font-weight: 700;
  background: var(--amigo-light-color);
}

.question_text {
  color: var(--amigo-primary-text-color);
  float: left;
  margin: 10px 4px 0px 0px;
  line-height: 1;
}

.modal__header {
  width: 100%;
  height: 74px;
  background-color: var(--amigo-light-color);
  padding: 6px 6px 6px 13px;
  display: flex;
  align-items: center;
}

#descriptiveQuestionPoints {
  width: 100%;
}

/* .descriptive__modal__content
{
  height: 380px;
} */
/* .descriptive__body__content
{
  position: absolute;
    bottom: 0px !important;
    width: 100%;
} */
.marksText {
  font-size: 12px;
  margin-top: 7px;
  color: var(--primary-text-color, #676a6c);
}

.comment_points_parent {
  left: 0%;
}

#zero-question-content {
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% - 65px));
  z-index: 999;
  position: absolute;
  display: grid;
  place-items: center;
}

.z-index-999 {
  z-index: 999;
}

.questionContentIcon {
  height: 120px;
  width: 120px;
  fill: var(--amigo-primary-color) !important;
}

.add-question-btn {
  top: 0px !important;
  left: 0 !important;
  padding: 0px 0px !important;
  /* width: 160px; */
  z-index: 1;
}

#nullText {
  padding-block: 10px;
  user-select: none;
  color: var(--menu-dropdown-text-color);
  font-size: 13px;
}

.btnPrimary {
  font-size: 13px;
  font-family: 'Century Gothic', Arial, sans-serif !important;
  color: white;
}

.new-question {
  color: white !important;
  margin-right: 5px;
}

#content-icon {
  display: grid !important;
  place-items: center !important;
}

.item_editable .svda-title-editor-start {
  padding-left: 2px !important;
}

.svda-drag-handle {
  padding-left: 8px !important;
}

#QuestionPopUp {
  display: grid;
  place-items: center;
}

.sa-visualizer__footer-content {
  display: block !important;
}

.sa-visualizer__toolbar {
  display: block !important;
}

.sa-visualizer__footer-title {
  float: left !important;
  color: var(--amigo-primary-color)!important;
  margin-right: 5px !important;
}

/* textarea{
  resize: none !important;
} */
.comment-dropdown {
  /* pointer-events: none !important; */
  display: none;
  cursor: default !important;
  position: relative;
  top: 4px;
  color: var(--amigo-primary-color) !important;
}

.comment-text-icon {
  padding-top: 2px;
}

.parent-comment-dropdown {
  cursor: default !important;
}

.comment-textarea {
  border-radius: 5px !important;
  margin-inline-start: 21px !important;
  /* width: calc(100% - 36px) !important; */
}

.display-none {
  display: none !important;
  border: 0px !important;
}

.icon-allignment {
  top: 3px !important;
  left: 8% !important;
}

.icon-toggle-on,
.icon-toggle-off {
  top: 5px !important;
  left: 3px !important;
  position: relative !important;
}

.change-toggle-svg-size {
  width: 36px !important;
  height: 16px !important;
  fill: var(--amigo-primary-color) !important;
}

.drag-icon-bottom {
  bottom: 10px !important;
}

.comment-drag-svg {
  bottom: 0px !important;
  position: absolute;
}

.creator-btn-parent {
  pointer-events: all !important;
}

#descriptiveAssessmentModelSurvey {
  overflow: auto !important;
}

.viewer-comment-border:focus {
  border-color: var(--amigo-primary-color) !important;
}

.assessment-marks {
  display: flex;
  margin-bottom: 5px;
}

#obtained-marks {
  position: relative;
  left: 10%;
  color: var(--amigo-primary-text-color);
  padding-left: 12px;
}

#total-marks {
  position: absolute;
  right: 10%;
  padding-right: 10px;
  color: var(--amigo-primary-text-color);
}

#AttemptContainer .scoreHeader {
  padding: 5px 12px 5px 5px;
}

.update-button-parent {
  position: relative;
  bottom: 40px;
  display: flex;
  justify-content: flex-end;
  width: 90%;
  pointer-events: all;
  margin-top: 11px;
  padding-right: 12px;
}

.update-button {
  color: var(--amigo-primary-color);
  cursor: pointer;
  background-color: transparent;
  border-color: var(--amigo-primary-color);
  border-radius: 4px;
  border: 1px solid;
  min-width: 78px;
  width: auto;
  height: 30px;
}

.update-button:hover {
  background-color: var(--amigo-primary-color) !important;
  color: var(--amigo-primary-bg-color) !important;
  border-color: var(--amigo-body-bg-color) !important;
}

.mat-dialog-container {
  width: 400px !important;
  background: var(--jquery-modal-body) !important;
  padding: 0px !important;
  overflow-x: hidden !important;
}

.mat-dialog-title {
  margin: 0px !important;
}

.mat-dialog-content {
  margin: 0px !important;
  padding: 0px 15px 0px 15px !important;
}

.mat-dialog-actions {
  margin-bottom: 0px !important;
  padding: 15px !important;
  background: var(--jquery-modal-footer) !important;
}

.answer-key-parent-div {
  display: flex;
  padding-top: 10px;
  flex-direction: row-reverse;
  width: 98.5%;
  pointer-events: all !important;
}

.image-picker_answer-key {
  cursor: pointer;
  color: var(--amigo-primary-color);
}

.sv_main.sv_main .sv_q_imgsel.checked label>div {
  background-color: var(--amigo-answer-bgcolor) !important;
}

#AttemptContainer .sv_q_imgsel.checked label>div {
  background-color: var(--amigo-primary-color) !important;
}

.sv_imgsel {
  margin-left: 1.5em !important;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_checkbox.sv_q_checkbox_inline:not(:last-child),
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_imgsel.sv_q_imagepicker_inline:not(:last-child),
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup.sv_q_radiogroup_inline:not(:last-child) {
  margin-right: 1em !important;
}

#assessmentID .sv_main.sv_main .sv_q_imgsel.checked label>div {
  background-color: transparent !important;
  border: none !important;
}

.modal-body .sv_q_imagepicker_inline:not(:last-child) {
  margin-right: 0px !important;
}

.modal-body .sv_main.sv_main .sv_q_imgsel {
  display: grid !important;
  place-items: center !important;
  margin-bottom: 10px !important;
}

.modal__content [class~='sv_q_imgsel']:last-of-type {
  padding-right: 10% !important;
}

.sv_imagepicker_root .svda-add-new-item {
  margin-left: 0px !important;
  border-color: var(--amigo-body-bg-color) !important;
}

.image_item_editable {
  margin-right: 5px !important;
}

.image-item-editor-custom-class {
  top: 88% !important;
  opacity: 1 !important;
}

#mainPreviewModal .sv_imgsel {
  display: flex;
}

.analytics-img {
  height: 40px;
  margin-bottom: 7px;
  max-width: 60px;
  border: 1px solid var(--amigo-body-bg-color);
}

.analytics-image-question-content {
  display: flex;
  margin-top: 25px;
  margin-bottom: 45px;
}

.analytics-image-div {
  margin: auto 0;
  display: grid;
  width: 60px;
  direction: rtl;
  gap: 5px;
}

.analytics-img__svg-container {
  position: absolute !important;
  top: 50px !important;
  left: 0px !important;
}

.image-picker_answer-key,
.creator-appended-btn:hover {
  text-decoration: none !important;
}

.image-picker_answer-key,
.creator-appended-btn {
  padding-left: 0px !important;
}

.st1-on {
  fill: #5491FF !important
}

.st0-on {
  fill: #C9DFFF !important
}

.st1-off {
  fill: var(--amigo-inverted-text-color) !important
}

.st0-off {
  fill: #9E9E9E !important
}

.separator-display-content {
  display: contents !important;
}

.disabled-btn,
.disabled-btn:hover {
  cursor: not-allowed !important;
  background-color: var(--amigo-disabled-color) !important;
  color: var(--amigo-disabled-text-color) !important;
  border: transparent !important;
}

/*changed the width of question statement*/

/* .sv_main .sv-title-actions__title{
  max-width: calc(90% - 11px) !important;
  /* display: inline-block !important; */
/* } */

.sv_main .sv-title-actions__title .sv-title-actions {
  max-width: calc(100% - 11px) !important;
}

#surveyCreatorContainer .sv_qstn:nth-child(1)>div>h5 {
  width: calc(90% - 60px) !important;
}

@media screen and (max-width:992px) {
  #surveyCreatorContainer .sv_qstn:nth-child(1)>div>h5 {
    width: calc(85% - 60px) !important;
  }
}

.survey-editor-page {
  background: var(--question-body-bg-color) !important;
}

.dropdown-text {
  color: var(--menu-dropdown-text-color) !important;
}

.marksText {
  color: var(--amigo-primary-text-color) !important;
}

.creator-container {
  background: var(--amigo-body-bg-color);
}

.svda-title-editor-placeholder {
  color: var(--amigo-primary-text-color) !important;
}

/* .sv-string-viewer{
  color: var(--amigo-primary-text-color) !important;
} */
.header-bg-color {
  background: var(--base-header-color) !important;
}

.marks-input-field,
.comment-textarea,
.setmarks {
  background: var(--text-area-bg) !important;
  color: var(--menu-dropdown-text-color) !important;
}

.comment-textarea {
  -webkit-text-fill-color: var(--menu-dropdown-text-color) !important;
}

.dialog-header {
  background: var(--amigo-dialog-header-bg-color);
}

.image-item-editor-custom-class {
  background: var(--question-img-item-bg-color) !important;
}

#AttemptContainer .sv_main.sv_default_css .sv_container {
  color: var(--assessment-item-color) !important;
}

.content-container .sa-question__select,
.content-container .sa-text-table__cell {
  background: var(--question-img-item-bg-color) !important;
  color: var(--amigo-primary-text-color) !important;
}

.sa-question__select-wrapper:before {
  border-right: 2px solid var(--menu-dropdown-svg-color) !important;
  border-bottom: 2px solid var(--menu-dropdown-svg-color) !important;
  color: var(--menu-dropdown-svg-color) !important;
}

/*Setting the Items size to normal*/
.sv_qcbc span,
#surveyAnalyticsContainer .ytick>text {
  font-weight: normal !important;
}
#surveyAnalyticsContainer{
  position: relative;
  /* top: -25px; */
}.mat-tab-label-content{
  color: var(--amigo-primary-text-color);
}
#surveyAnalyticsContainer .sa-question__title {
  color: var(--amigo-primary-text-color) !important;
}
.mat-tab-label-active .mat-tab-label-content{
  color: var(--material-primary-color) !important;
}
.dataTables_wrapper, .no-footer,#csvContainer .sa-table__svg-button>svg,.sa-table__detail-row.sa-table__detail-row.sa-table__detail-row.sa-table__detail-row,.sa-table__detail-table{
  background-color: var(--question-body-bg-color) !important;
}
#csvContainer .even,#csvContainer .odd,#csvContainer .sa-datatables__table,#csvContainer .sa-table__entries-label,#csvContainer select{
  color: var(--amigo-primary-text-color) !important;
}
.preview-question-type-parent {
  float: right;
  background: var(--question-item-bg-color) !important;
  color: var(--amigo-primary-text-color) !important;
  /* width: 100px; */
  max-width: 120px;
  min-width: 100px;
  height: calc(2em + 1px);
  margin-right: 16px;
  position: relative;
  margin-left: auto;
  /* bottom: 8px; */
}
#mainPreviewModal .sv_q_title span:nth-child(3) {
  line-height: 25px !important;
}

.preview-question-type {
  font-weight: normal;
  color: var(--amigo-primary-text-color) !important;
  display: grid;
  place-items: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  width: calc(100% - 12px);
}

.arrow-dropdown {
  width: 20px;
  height: 20px;
  fill: var(--menu-dropdown-svg-color);
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-preview-caret {
  margin-left: 5px;
  margin-right: 3px;
}

.d-flex {
  display: flex;
}

.color-default {
  color: var(--menu-dropdown-svg-color) !important;
}

.item-editor-custom-class {
  padding-left: 0em !important;
}

.margin-left-10 {
  margin-left: 10px;
}

.edit-form-name {
  width: 16px;
  height: 16px;
}

.cursor-pointer:hover {
  cursor: pointer;
}

/* .form-header{
  background-color: var(--amigo-body-bg-color);
} */
.svd_surveyjs_designer_container.svd_surveyjs_designer_container .sv_row .svd_question question-actions .svda_question_action .icon-actiondragelement {
  bottom: 4px !important;
  line-height: 0.1 !important;
}

/* .sv_main.sv_default_css .sv_body{
  padding-bottom: 40px !important;
  margin: 0px !important;
}
#previewID, #AttemptContainer{
  height: calc(100% - 50px) !important;
}
.svd_container .svd_content .svd_survey_designer .svd_editors .svd_questions_editor{
  padding: 0px !important;
} */
#surveyCreatorContainer .svd-vertical-container__row {
  display: table !important;
  padding-bottom: 40px !important;
}

#previewID {
  height: calc(100% - 30px) !important;
  padding: 10px !important;
}

#previewID #surveyElement {
  padding-bottom: 40px !important;
}

#AttemptContainer #surveyElement {
  padding-bottom: 120px !important;
}

#assessmentID .sv_body,
.containeranalytics {
  padding-bottom: 40px !important;
}

.creator-score {
  padding: 5px 18px 5px 5px;
}

#creatorAddDiv.displaynone~.container- .svd-vertical-container__cell-content--scrollable {
  overflow: hidden !important;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.popup {
  position: absolute;
  width: 500px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup-image {
  max-width: 100%;
}

.sv_q_num,
.sv-title-actions__title .sv-string-viewer,
.sv_qstn:nth-child(1)>div>h5,
.sa-question__title .sa-question__title--draggable {
  color: var(--amigo-primary-text-color) !important;
}
#lblFormTitle{
  color: var(--amigo-primary-text-color);
}

.typeselectdropdown,
.comment-question-dropdown,
.img-question-label {
  font-weight: 400 !important;
}

/* #mainPreviewModal .sv_q_num{
margin-left: 25px !important;
} */
/*TODO question description*/
.question-description-span,
.sv_q_description {
  /* margin-left: 27px !important; */
  font-size: 12px !important;
}

.input-update {
  margin-left: 2px !important;
  -webkit-text-fill-color: var(--amigo-primary-text-color) !important;
  color: var(--amigo-primary-text-color) !important;
}

.sv_q_title span:nth-child(2) {
  margin-left: 3px !important;
}
.d-none,.sa-table__header-extensions,.dataTables_paginate,.sorting_disabled>.sa-table__action-container,.sa-table__svg-button,.dataTables_info,.sorting_asc,.sorting_1{
  display: none !important;
}
.buttons-copy,.buttons-csv,.buttons-print{
  color: black !important;
}
.btnFilledBlue{
  background-color: var(--amigo-primary-color);
  border: 1px solid;
  border-color: var(--amigo-primary-color);
  color: #fff;
  cursor: pointer;
  /* float: right;
  margin: 10px 0px 0px 10px; */
}
.export-btn-parent{
  display: flex;
  justify-content: end;
}
.rbcButtons{
  position: relative;
  min-width: 74px;
  max-width: 124px;
  height: 30px;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  outline: 0;
  transition: all .3s ease;
}
.dots-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  column-gap: 5px;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #fff;
  border-radius: 50%;
  animation: jumping 1s infinite linear;
}
@keyframes jumping {
  0% {
      transform: translateY(0px);
  }
  25% {
      transform: translateY(-2px);
  }
  50% {
      transform: translateY(0px);
  }
  75% {
      transform: translateY(2px);
  }
  100% {
      transform: translateY(0px);
  }
}
.csv-export-parent{
  padding: 10px 10px;
}
.creator-export-btn-parent{
  right: 10px;
  margin-top: 10px;
  position: relative;
  margin-left: 10px;
  margin-right: 5px;
}
.export-oprenforrespose-parent{
  display: flex;
  justify-content: end;
}
.mat-ink-bar{
  background: var(--material-primary-color) !important;
}
#csvContainer .dt-button{
  display: none !important;
}
.sa-table__header-extensions{
    margin-bottom: 10px !important;
}
.default-blue-fill-btn,#csvContainer .dt-button,#csvContainer .sa-table__btn{
  background-color: var(--amigo-primary-color) !important;
  border: 1px solid !important;
  border-color: var(--amigo-primary-color)!important;
  color: #fff !important;
  cursor: pointer !important;
  position: relative!important;
  min-width: 74px !important;
  max-width: 124px!important;
  height: 30px !important;
  border-radius: 3px !important;
  padding: 5px 10px !important;
  font-size: 13px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  outline: 0 !important;
  transition: all .3s ease !important;
}
#sa-svg-movetodetails,.primary-fill-color{
  fill: var(--amigo-primary-color) !important;
}
.sa-table input:focus{
  border-color: var(--amigo-primary-text-color)!important;
  /* color: var(--amigo-primary-text-color)!important; */
}
#csvContainer .sa-table input,.sa-table__global-filter.sa-table__global-filter, .sa-table__filter.sa-table__filter{
  color: var(--amigo-primary-text-color)!important;
}


/* .primary-dropdwon-fill-color polygon{
  fill: var(--amigo-primary-text-color) !important;
} */
.sa-table select:focus,.sa-table select {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' class='primary-dropdwon-fill-color' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E %7Bfill:%23d5d8dc;%7D%0A%3C/style%3E%3Cpolygon points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E fill='#fff'") !important;
}
.form-control:focus{
 border-color:  var(--amigo-primary-text-color) !important;
 box-shadow:none !important;
}
/* .dataTables_scroll,.sa-datatables .dataTables_wrapper{
  padding: 20px !important;
} */
.sa-datatables__table{
  width: 100% !important;
}
.sa-datatables table.dataTable > thead > tr > th,.dataTables_scrollBody td{
  border-left: 1px solid #d4d4d4 !important;
}
.sa-datatables table.dataTable > thead > tr > th{
  border: none !important;
}
#csvContainer tbody{
  background: var(--table-body-background) !important;
}
#csvContainer tbody>tr{
  height: 48px !important;
  min-height: 48px !important;
}
.sa-datatables table.dataTable > tbody > tr > td{
  border-top: 1px solid rgba(0,0,0,.12) !important;
  border-bottom: 1px solid rgba(0,0,0,.12) !important;
  border-left: none !important;
  border-right: none !important;
}
.sa-datatables .dataTables_wrapper{
    padding-top: 0px !important;
     padding-bottom: 0px !important;
}
.exported-description-img{
  margin-left: 3px;
}.result-exported-description-img,.asssesment-exported-description-img{
  transform: translateX(20px);
  margin-bottom: 10px;
}
.sv_q >div>span{
  color: var(--amigo-primary-text-color) !important;
}
#preview-scrollable-div .sv_q_description,#assessment-scrollable-div .sv_q_description,#view-scrollable-div .sv_q_description{
  display: block !important;
}
#scrollableDiv .question-description-span{
  margin-left: 15px !important;
}
#scrollableDiv .desc-preview-img{
margin-left: 3px;

}
#scrollableDiv .desc-preview-img:hover{
cursor: pointer;
}
option {
 padding-block: 24px !important;
 height: 48px !important;
 min-height: 48px !important;
 max-height: 48px !important;
}
.default-svg-size{
    height: 16px;
    width: 16px;
    fill: var(--amigo-primary-color) !important;
}
.img-preview-description{
  display: block !important;
}
.modal-heading-parent{
  display: flex;
  align-items: baseline;
}
.preview-model-description,.analytics-description{
  display: flex !important;
  align-items: center !important;
}
.item_editable .sv-string-viewer,.svda-add-custom-item,.sv_qcbc span{
  font-size: 13px !important;
}
.form-control:focus{
  border-color: transparent !important;
}
.question-description-span .svda-title-editor-content{
  margin-left: 10px !important;
}
.sa-grid{
  margin-bottom: 20px !important;
}
.analytics-image-desc{
  border: 1px solid var(--amigo-body-bg-color);
  margin-right: 5px;
}
.mat-tab-label{
  opacity: 1 !important;
}
.creator-label{
  bottom: 0px !important;
}
/* .muuri-item-shown{
  padding-bottom: 0px !important;
  padding-top: 0px !important;
} */
